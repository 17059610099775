import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { AuthContext } from "../components/AuthProvider";
import { Navigate } from "react-router-dom";
import { registerUser } from "../api/user";
import { ErrorsContext } from "../components/ErrorsProvider";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      Šaltiena {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const [needRegister, setNeedRegister] = React.useState(false);
  const auth = React.useContext(AuthContext);
  const err = React.useContext(ErrorsContext);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    if (email != null && typeof email === "string") {
      if (needRegister) {
        const name = data.get("name");
        if (name != null && typeof name === "string") {
          try {
            await registerUser(email, name);
            setNeedRegister(false);
          } catch (error) {
            err.setError((error as Error).message);
          }
        }
      } else {
        try {
          let user = await auth.signin(email);
          if (user === null) {
            setNeedRegister(true);
          }
        } catch (error) {
          err.setError((error as Error).message);
        }
      }
    }
  };

  if (auth.user === null) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#eee" }}>
            <img src="logo192.png" alt="" width={32} />
          </Avatar>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onKeyDown={() => {
                setNeedRegister(false);
              }}
            />
            {needRegister && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="name"
                label="Name"
                id="name"
                autoComplete="name"
              />
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {needRegister ? "Register" : "Sign In"}
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    );
  } else {
    return <Navigate to="/" replace />;
  }
}
