import { Alert, Button, Paper, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addChallange, ChallangeType } from "../api/challange";

export default function AddChallange() {
  const [date, setDate] = useState(new Date());
  const [type, setType] = useState<ChallangeType | "">("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    const data = new FormData(event.currentTarget);
    const rawDate = data.get("date");
    let inputDate;
    if (rawDate !== null && typeof rawDate === "string") {
      inputDate = new Date(rawDate);
    } else {
      setError("Wrong date");
      return;
    }
    const inputType = data.get("type");
    if (
      inputType === null ||
      inputType === "" ||
      typeof inputType !== "string"
    ) {
      setError("Please select challange type");
      return;
    }
    const inputNote = data.get("note") || "";
    if (inputNote === null || typeof inputNote !== "string") {
      setError("Wrong note");
      return;
    }
    try {
      await addChallange({
        date: inputDate,
        type: inputType as ChallangeType,
        note: inputNote,
      });
      cleanup();
    } catch (error) {
      setError("Sorry. Unexpected creation error!");
    }
  };

  const cleanup = () => {
    setDate(new Date());
    setType("");
    navigate("/", { replace: true });
  };
  return (
    <Grid2 container>
      <Grid2 xs />
      <Grid2 xs={12} sm={10} md={8} lg={6} xl={4}>
        <Paper sx={{ p: 2, mt: 10 }}>
          <Grid2 container>
            <Grid2 xs={12}>
              <Typography variant="h4">Add challange</Typography>
            </Grid2>
            <Grid2 xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateCalendar
                  value={date}
                  disableFuture
                  autoFocus
                  onChange={(val) => {
                    if (val !== null) {
                      setDate(val);
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid2>
            <Grid2 xs={12}>
              <Grid2 container columnSpacing={1}>
                <Grid2
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    fullWidth
                    aria-label="shower"
                    size="large"
                    variant={type === "shower" ? "contained" : "outlined"}
                    onClick={() => {
                      setType("shower");
                    }}
                  >
                    🚿
                  </Button>
                </Grid2>
                <Grid2
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    fullWidth
                    aria-label="dive"
                    size="large"
                    variant={type === "dive" ? "contained" : "outlined"}
                    onClick={() => {
                      setType("dive");
                    }}
                  >
                    🧊
                  </Button>
                </Grid2>
              </Grid2>
            </Grid2>
            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
              <Grid2 xs={12}>
                <TextField
                  id="type"
                  name="type"
                  type="hidden"
                  value={type}
                  sx={{ display: "none" }}
                  required
                />
                <TextField
                  id="date"
                  name="date"
                  type="hidden"
                  value={date}
                  sx={{ display: "none" }}
                  required
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="note"
                  label="Note"
                  name="note"
                  multiline
                  rows={2}
                />
              </Grid2>
              <Grid2 xs={12}>
                <Grid2 container columnSpacing={1}>
                  <Grid2 xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      type="submit"
                      color="success"
                    >
                      Add
                    </Button>
                  </Grid2>
                  <Grid2 xs={6}>
                    <Button
                      fullWidth
                      color="warning"
                      variant="outlined"
                      onClick={cleanup}
                    >
                      Cancel
                    </Button>
                  </Grid2>
                </Grid2>
              </Grid2>
              {error !== "" ? (
                <Grid2 xs={12}>
                  <Alert severity="error">{error}</Alert>
                </Grid2>
              ) : (
                ""
              )}
            </form>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 xs />
    </Grid2>
  );
}
