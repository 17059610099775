import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AddChallange from "../components/AddChallange";
import { AuthContext } from "../components/AuthProvider";
import Menu from "../components/Menu";

function Add() {
  const auth = useContext(AuthContext);

  if (auth.user === null) {
    return <Navigate to="/signin" replace />;
  } else {
    return (
      <Menu>
        <AddChallange />
      </Menu>
    );
  }
}

export default Add;
