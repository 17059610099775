import React, { createContext, useRef, useState } from "react";

interface TimerContextType {
  time: number;
  trigger: () => void;
  setTimout: () => void;
}

export const RefetchContext = createContext<TimerContextType>(null!);

function RefetchProvider({ children }: { children: React.ReactNode }) {
  let timeout = useRef<NodeJS.Timeout>();
  const [refetchTime, setRefetchTIme] = useState(Date.now());

  const setTimeoutCallback = () => {
    if (timeout !== undefined) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setRefetchTIme(Date.now());
    }, 10000);
  };

  const triggerRefetch = () => {
    setTimeoutCallback();
    setRefetchTIme(Date.now());
  };

  const value: TimerContextType = {
    time: refetchTime,
    trigger: triggerRefetch,
    setTimout: setTimeoutCallback,
  };

  return (
    <RefetchContext.Provider value={value}>{children}</RefetchContext.Provider>
  );
}

export default RefetchProvider;
