import { CardMedia, Skeleton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  APIChallangeType,
  ChallangeImageType,
  getChallangeImage,
} from "../api/challange";
import { ErrorsContext } from "./ErrorsProvider";

interface PropsType {
  challange: APIChallangeType;
}

function ChallangeImage(props: PropsType) {
  const err = useContext(ErrorsContext);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<ChallangeImageType>();
  useEffect(() => {
    setLoading(true);
    getChallangeImage(props.challange.UUID)
      .then((img) => {
        setImage(img);
      })
      .catch((error) => {
        console.error(error);
        err.setError("Image load failed!");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.challange.UpdatedAt, props.challange.UUID, err]);

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    for (var i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  if (loading) {
    return (
      <CardMedia component={Skeleton} height={300} variant="rectangular" />
    );
  }
  if (image !== undefined) {
    return (
      <CardMedia
        component="img"
        src={`data:${image.type};base64,${arrayBufferToBase64(image.data)}`}
      />
    );
  } else {
    return <></>;
  }
}

export default ChallangeImage;
