import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { format } from "date-fns";
import deepEqual from "deep-equal";
import { useState, useContext, useEffect, useRef } from "react";
import { APIChallangeType, getChallanges } from "../api/challange";
import { APIUserType, getUsers } from "../api/user";
import { AuthContext } from "./AuthProvider";
import ChallangeActions from "./ChallangeActions";
import ChallangeImage from "./ChallangeImage";
import { ErrorsContext } from "./ErrorsProvider";
import { RefetchContext } from "./RefetchProvider";

function ChallangesFeed() {
  const [challanges, setChallanges] = useState<APIChallangeType[]>([]);
  const previousChallanges = useRef<APIChallangeType[]>([]);
  const [users, setUsers] = useState<APIUserType[]>([]);
  const previousUsers = useRef<APIUserType[]>([]);

  const err = useContext(ErrorsContext);
  const auth = useContext(AuthContext);
  const refetch = useContext(RefetchContext);

  useEffect(() => {
    const fetchChallanges = async () => {
      try {
        const usersData = await getUsers();
        if (!deepEqual(usersData, previousUsers.current)) {
          previousUsers.current = usersData;
          setUsers(usersData);
        }

        const chalangesData = await getChallanges();
        if (!deepEqual(chalangesData, previousChallanges.current)) {
          previousChallanges.current = chalangesData;
          setChallanges(chalangesData);
        }
      } catch (error) {
        err.setError((error as Error).message);
      }
      refetch.setTimout();
    };
    fetchChallanges();
  }, [err, refetch.time, refetch]);

  const getUserNameByUserUUID = (uuid: string) => {
    for (const user of users) {
      if (user.UUID === uuid) {
        return user.Name;
      }
    }
    throw new Error("User was not found");
  };

  const renderNote = (challange: APIChallangeType) => {
    if (challange.Note) {
      return (
        <CardContent>
          <Typography>
            <b>Note:</b> {challange.Note}
          </Typography>
        </CardContent>
      );
    }
  };
  const renderActions = (challange: APIChallangeType) => {
    if (challange.UserUUID === auth.user?.uuid) {
      return <ChallangeActions challangeUUID={challange.UUID} />;
    }
  };

  return (
    <Grid2 container sx={{ width: "100%", mb: 5 }}>
      <Grid2 xs={12}>
        <Typography variant="h3" textAlign="center">
          Feed
        </Typography>
      </Grid2>
      <Grid2 xs={12}>
        <Grid2 container>
          <Grid2 xs></Grid2>
          <Grid2 xs={12} sm={10} md={8} lg={6} xl={4}>
            <>
              {challanges
                .sort((a, b) => {
                  return b.UpdatedAt.getTime() - a.UpdatedAt.getTime();
                })
                .map((challange) => {
                  return (
                    <Card
                      key={challange.UUID}
                      variant="outlined"
                      sx={{ mb: 1 }}
                    >
                      <>
                        <CardHeader
                          title={`${
                            challange.Type === "dive" ? "🧊" : "🚿"
                          } ${getUserNameByUserUUID(challange.UserUUID)}`}
                          subheader={format(challange.Date, "yyyy-MM-dd")}
                        />
                        <ChallangeImage challange={challange} />
                        {renderNote(challange)}
                        {renderActions(challange)}
                      </>
                    </Card>
                  );
                })}
            </>
          </Grid2>
          <Grid2 xs></Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export default ChallangesFeed;
