import {
  AppBar,
  Toolbar,
  Avatar,
  Button,
  IconButton,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

function Menu(props: { children: React.ReactNode }) {
  const auth = useContext(AuthContext);
  const { pathname } = useLocation();

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton href="/" sx={{ p: 0 }}>
            <Avatar sx={{ m: 1, bgcolor: "#eee" }}>
              <img src="logo192.png" alt="" width={32} />
            </Avatar>
          </IconButton>
          <Tabs
            value={pathname === "/top" || pathname === "/feed" ? pathname : 2}
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              sx: { backgroundColor: "#0C2D48" },
            }}
            sx={{ flexGrow: 1 }}
          >
            <Tab label="Top" value="/top" href="/top" />
            <Tab label="Feed" value="/feed" href="/feed" />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mr: 1, ml: "auto" }}
            />
            <Tab label={`Logged in user: ${auth.user?.name}`} disabled />
          </Tabs>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Button
            color="inherit"
            onClick={() => {
              auth.signout();
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {props.children}
    </>
  );
}

export default Menu;
