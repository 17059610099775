import { isAxiosError } from "axios";
import api from "./api";

export interface APIChallangeType {
  UUID: string;
  UserUUID: string;
  Type: ChallangeType;
  Note: string;
  Date: Date;
  CreatedAt: Date;
  UpdatedAt: Date;
}
export type ChallangeType = "dive" | "shower";

export interface APIChallangeSummaryType {
  [key: string]: APIChallangeSummaryItemType;
}
interface APIChallangeSummaryItemType {
  ChallangeSummary: { [key: string]: number };
  UserName: string;
  UserUsername: string;
}

export async function getChallangeSummary(): Promise<APIChallangeSummaryType> {
  try {
    const res = await api.get<APIChallangeSummaryType>("/challange/summary");
    return res.data;
  } catch (error) {
    throw error;
  }
}

export interface APIAddChallangeInputType {
  type: ChallangeType;
  date: Date;
  note: string;
}

export async function addChallange(
  params: APIAddChallangeInputType
): Promise<APIChallangeType> {
  try {
    const res = await api.post<APIAddChallangeInputType, APIChallangeType>(
      "/challange",
      params
    );
    return res;
  } catch (error) {
    throw error;
  }
}
export interface APIGetUserChallangesInputType {}

export async function getUserChallanges(
  params: APIGetUserChallangesInputType
): Promise<APIChallangeType[]> {
  try {
    const res = await api.get<APIChallangeType[]>(`/user/${params}/challange`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function getChallanges(): Promise<APIChallangeType[]> {
  try {
    const res = await api.get<APIChallangeType[]>(`challange`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteChallange(uuid: string) {
  try {
    await api.delete(`/challange/${uuid}`);
  } catch (error) {
    throw error;
  }
}

export async function updateChallangeImage(
  challangeUUID: string,
  data: FormData
) {
  try {
    await api.put(`challange/${challangeUUID}/image`, data);
  } catch (error) {
    throw error;
  }
}

export interface ChallangeImageType {
  data: ArrayBuffer;
  type?: string;
}

export async function getChallangeImage(
  challangeUUID: string
): Promise<ChallangeImageType | undefined> {
  try {
    const res = await api.get<ArrayBuffer>(`challange/${challangeUUID}/image`, {
      responseType: "arraybuffer",
    });
    return { data: res.data, type: res.headers["Content-Type"]?.toString() };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 404) {
        return undefined;
      }
    }
    throw error;
  }
}
