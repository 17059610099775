import api from "./api";

export interface APIUserType {
  UUID: string;
  Name: string;
  Username: string;
  CreatedAt: Date;
  UpdatedAt: Date;
}
interface APICreateUserInputType {
  name: string;
  username: string;
}

export async function getUserByUsername(
  username: string
): Promise<APIUserType> {
  try {
    const res = await api.get<APIUserType>(
      `/username/${username.toLocaleLowerCase()}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function registerUser(
  username: string,
  name: string
): Promise<APIUserType> {
  try {
    const res = await api.post<APICreateUserInputType, APIUserType>("/user", {
      username: username.toLocaleLowerCase(),
      name,
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getUsers(): Promise<APIUserType[]> {
  try {
    const res = await api.get<APIUserType[]>(`/user`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
