import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { createContext, useState } from "react";

interface ErrorsContextType {
  setError: (error: string, severity?: AlertColor) => void;
  clearError: () => void;
}
export const ErrorsContext = createContext<ErrorsContextType>(null!);

function ErrorsProvider({ children }: { children: React.ReactNode }) {
  const [error, setError] = useState("");
  const [severity, setSeverity] = useState<AlertColor>();

  const clearError = () => {
    setError("");
  };

  const value: ErrorsContextType = {
    setError: (error, severity) => {
      setError(error);
      if (severity === undefined) {
        setSeverity("error");
      } else {
        setSeverity(severity);
      }
    },
    clearError,
  };

  return (
    <ErrorsContext.Provider value={value}>
      {children}
      <Snackbar
        open={error !== ""}
        autoHideDuration={3000}
        onClose={clearError}
      >
        <Alert severity={severity}>{error}</Alert>
      </Snackbar>
    </ErrorsContext.Provider>
  );
}

export default ErrorsProvider;
