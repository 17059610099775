import { Container, Box } from "@mui/material";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../components/AuthProvider";
import Menu from "../components/Menu";
import ChallangesFeed from "../components/ChallangesFeed";
import { useContext } from "react";
import RefetchProvider from "../components/RefetchProvider";
import AddChallangeButton from "../components/AddChallangeButton";

export default function Feed() {
  const auth = useContext(AuthContext);
  if (auth.user === null) {
    return <Navigate to="/signin" replace />;
  } else {
    return (
      <Menu>
        <RefetchProvider>
          <Container component="main">
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ChallangesFeed />
            </Box>
          </Container>
          <AddChallangeButton />
        </RefetchProvider>
      </Menu>
    );
  }
}
