import {
  Button,
  Card,
  CardContent,
  Modal,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { APIChallangeType, getUserChallanges } from "../api/challange";
import ChallangeImage from "./ChallangeImage";
import { ErrorsContext } from "./ErrorsProvider";

interface PropsType {
  userUUID: string;
  show: boolean;
  close: () => void;
}

export default function UserChallanges(props: PropsType) {
  const [challanges, setChallanges] = useState<APIChallangeType[]>([]);
  const err = useContext(ErrorsContext);

  const cleanup = () => {
    props.close();
  };

  useEffect(() => {
    if (props.userUUID === "") {
      return;
    }
    setChallanges([]);
    getUserChallanges(props.userUUID)
      .then((data) => {
        setChallanges(data);
      })
      .catch((error) => {
        err.setError((error as Error).message);
      });
  }, [props.userUUID, err]);

  return (
    <Modal open={props.show} onClose={cleanup}>
      <Grid2 container>
        <Grid2 xs />
        <Grid2 xs={12} sm={10} md={8} lg={6} xl={4}>
          <Paper sx={{ p: 2, mt: 10 }}>
            <Grid2 container rowSpacing={1}>
              <Grid2 xs={12}>
                <Typography variant="h4">User challanges</Typography>
              </Grid2>
              <Grid2 xs={12} sx={{ maxHeight: "400px", overflow: "auto" }}>
                <>
                  {challanges.length === 0 ? (
                    <>
                      <Skeleton height={100} />
                      <Skeleton height={100} />
                      <Skeleton height={100} />
                      <Skeleton height={100} />
                    </>
                  ) : (
                    challanges
                      .sort((a, b) => {
                        return b.Date.getTime() - a.Date.getTime();
                      })
                      .map((challange) => {
                        return (
                          <Card
                            key={challange.UUID}
                            variant="outlined"
                            sx={{ mb: 1 }}
                          >
                            <CardContent>
                              <Typography>
                                <b>When:</b>{" "}
                                {format(challange.Date, "yyyy-MM-dd")}
                              </Typography>
                              <Typography>
                                <b>What:</b>{" "}
                                {challange.Type === "dive" ? "🧊" : "🚿"}
                              </Typography>
                              <Typography>
                                <b>Note:</b> {challange.Note}
                              </Typography>
                            </CardContent>
                            <ChallangeImage challange={challange} />
                          </Card>
                        );
                      })
                  )}
                </>
              </Grid2>
              <Grid2 xs={12}>
                <Button
                  fullWidth
                  color="warning"
                  variant="outlined"
                  onClick={cleanup}
                >
                  Close
                </Button>
              </Grid2>
            </Grid2>
          </Paper>
        </Grid2>
        <Grid2 xs />
      </Grid2>
    </Modal>
  );
}
