import { isAxiosError } from "axios";
import React, { useEffect } from "react";
import { clearUserHeader, setUserHeader } from "../api/api";
import { getUserByUsername } from "../api/user";

const USER_STORAGE_KEY = "user";
interface UserType {
  uuid: string;
  name: string;
  username: string;
}

interface AuthContextType {
  user: UserType | null;
  signin: (username: string) => Promise<UserType | null>;
  signout: () => void;
}

export let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<UserType | null>(getUserFromStorage());

  function getUserFromStorage(): UserType | null {
    let rawUser = localStorage.getItem(USER_STORAGE_KEY);
    if (rawUser == null) {
      return null;
    }
    const loggedInUser: UserType = JSON.parse(rawUser);
    return loggedInUser;
  }

  function saveUserToStorage(user: UserType) {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
  }
  function cleanUserStorage() {
    localStorage.removeItem(USER_STORAGE_KEY);
  }

  function setSignedInState(loggedInUser: UserType) {
    setUserHeader(loggedInUser.uuid);
    saveUserToStorage(loggedInUser);
    setUser(loggedInUser);
  }

  let signin = async (username: string): Promise<UserType | null> => {
    try {
      const u = await getUserByUsername(username);
      const loggedInUser: UserType = {
        name: u.Name,
        username: u.Username,
        uuid: u.UUID,
      };
      setSignedInState(loggedInUser);
      return loggedInUser;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status !== 404) {
          throw error;
        }
      } else {
        throw error;
      }
      return null;
    }
  };

  let signout = () => {
    clearUserHeader();
    cleanUserStorage();
    setUser(null!);
  };

  let value = { user, signin, signout };

  useEffect(() => {
    if (user === null) {
      signout();
    } else {
      setSignedInState(user);
    }
  });

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
