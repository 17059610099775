import {
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Paper,
  Table,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import deepEqual from "deep-equal";
import { useContext, useEffect, useRef, useState } from "react";
import { APIChallangeSummaryType, getChallangeSummary } from "../api/challange";
import { AuthContext } from "./AuthProvider";
import { ErrorsContext } from "./ErrorsProvider";
import { RefetchContext } from "./RefetchProvider";

export interface ChallangesSummaryType {
  user: {
    uuid: string;
    name: string;
    username: string;
  };
  challanges: {
    shower: number;
    dive: number;
    points: number;
  };
}

interface PropsType {
  currentUserUUID: string;
  onRowClick: (userUUID: string) => void;
}

export default function ChallangesSummary(props: PropsType) {
  const err = useContext(ErrorsContext);
  const auth = useContext(AuthContext);
  const refetch = useContext(RefetchContext);

  const previousChallangeSummary = useRef<APIChallangeSummaryType>();
  const [challangeSummary, setChallangeSummary] = useState<
    ChallangesSummaryType[]
  >([]);

  useEffect(() => {
    const fetchChallangeSummary = async () => {
      try {
        const data = await getChallangeSummary();
        if (deepEqual(previousChallangeSummary.current, data)) {
          return;
        }
        previousChallangeSummary.current = data;
        setChallangeSummary(prepareChallangesData(data));
      } catch (error) {
        err.setError((error as Error).message);
      }

      refetch.setTimout();
    };

    fetchChallangeSummary();
  }, [auth.user, refetch.time, err, refetch]);

  const prepareChallangesData = (
    data: APIChallangeSummaryType
  ): ChallangesSummaryType[] => {
    return Object.keys(data)
      .map((uuid) => {
        const shower = data[uuid].ChallangeSummary["shower"] | 0;
        const dive = data[uuid].ChallangeSummary["dive"] | 0;
        return {
          user: {
            uuid,
            name: data[uuid].UserName,
            username: data[uuid].UserUsername,
          },
          challanges: {
            shower,
            dive,
            points: shower + dive * 5,
          },
        };
      })
      .sort((a, b) => {
        return b.challanges.points - a.challanges.points;
      });
  };

  return (
    <Grid2 container sx={{ width: "100%", mb: 5 }}>
      <Grid2 xs={12}>
        <Typography variant="h3" textAlign="center">
          Top
        </Typography>
      </Grid2>
      <Grid2 xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Name </TableCell>
                <TableCell align="right">🚿</TableCell>
                <TableCell align="right">🧊</TableCell>
                <TableCell align="right">
                  <Tooltip title="Shower - 1 Dive - 5" placement="right">
                    <Typography>🪙</Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {challangeSummary.map((ch) => {
                return (
                  <TableRow
                    key={ch.user.uuid}
                    onClick={() => {
                      props.onRowClick(ch.user.uuid);
                    }}
                    sx={{
                      cursor: "pointer",
                      fontWeight:
                        ch.user.uuid === auth.user?.uuid
                          ? "bold !important"
                          : "normal",
                    }}
                  >
                    <TableCell sx={{ fontWeight: "inherit" }}>
                      {ch.user.name}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "inherit" }} align="right">
                      {ch.challanges.shower}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "inherit" }} align="right">
                      {ch.challanges.dive}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "inherit" }} align="right">
                      {ch.challanges.points}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
    </Grid2>
  );
}
