import axios from "axios";
import { parse, isValid } from "date-fns";

const USER_HEADER = "USER-UUID";

const api = axios.create({
  baseURL: "https://saltiena-be.rad-iq.com/",
});

let userUUID: string | null = null;

api.interceptors.request.use((config) => {
  if (userUUID !== null) {
    config.headers[USER_HEADER] = userUUID;
  }
  return config;
});

api.interceptors.response.use((res) => {
  handleDates(res.data);
  return res;
});

export function setUserHeader(uuid: string) {
  userUUID = uuid;
}

export function clearUserHeader() {
  userUUID = null;
}

function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (typeof value === "object") handleDates(value);
    else {
      const date = parse(value, "yyyy-MM-dd'T'HH:mm:ssXX", 0);
      if (isValid(date)) {
        body[key] = date;
      }
    }
  }
}

export default api;
