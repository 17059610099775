import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthProvider from "./components/AuthProvider";
import Dashboard from "./pages/Dashboard";
import SignIn from "./pages/SignIn";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ErrorsProvider from "./components/ErrorsProvider";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline } from "@mui/material";
import Feed from "./pages/Feed";
import Home from "./pages/Home";
import Add from "./pages/Add";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#B1D4E0",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorsProvider>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/top" element={<Dashboard />} />
              <Route path="/feed" element={<Feed />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/add" element={<Add />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </ErrorsProvider>
    </ThemeProvider>
  );
}

export default App;
