import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

interface PropsType {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  message?: string;
}

function Confirm(props: PropsType) {
  const renderMessage = () => {
    if (props.message !== undefined) {
      return (
        <DialogContent>
          <Typography>{props.message}</Typography>
        </DialogContent>
      );
    }
  };
  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <DialogTitle>Are you sure?</DialogTitle>
      {renderMessage()}
      <DialogActions>
        <Button
          variant="outlined"
          color="warning"
          fullWidth
          onClick={props.onConfirm}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          color="success"
          fullWidth
          onClick={props.onCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Confirm;
