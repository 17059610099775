import { Button, CardActions, Divider } from "@mui/material";
import { useContext, useState } from "react";
import { deleteChallange, updateChallangeImage } from "../api/challange";
import Confirm from "./Confirm";
import { ErrorsContext } from "./ErrorsProvider";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { RefetchContext } from "./RefetchProvider";

interface PropsType {
  challangeUUID: string;
}

function ChallangeActions(props: PropsType) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const err = useContext(ErrorsContext);
  const refetch = useContext(RefetchContext);

  return (
    <>
      <Divider />
      <CardActions>
        <Button
          component="label"
          variant="outlined"
          startIcon={<UploadFileIcon />}
          sx={{ marginRight: "1rem" }}
        >
          Upload Picture
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={async (e) => {
              if (!e.target.files) {
                return;
              }

              const file = e.target.files[0];
              const data = new FormData();
              data.append("image", file);
              try {
                await updateChallangeImage(props.challangeUUID, data);
                refetch.trigger();
              } catch (error) {
                err.setError("Image upload failed!");
              }
            }}
          />
        </Button>
        <Button
          variant="outlined"
          color="warning"
          onClick={() => {
            setConfirmOpen(true);
          }}
        >
          DELETE
        </Button>
      </CardActions>
      <Confirm
        open={confirmOpen}
        onConfirm={async () => {
          setConfirmOpen(false);
          try {
            await deleteChallange(props.challangeUUID);
            refetch.trigger();
          } catch (error) {
            err.setError("Deleting challange failed!");
          }
        }}
        onCancel={() => {
          setConfirmOpen(false);
        }}
        message="This will delete the recorded challange irreversibly!"
      />
    </>
  );
}

export default ChallangeActions;
